<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="标题">
          <el-input size="small" v-model="searchData.title" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="导航类型">
          <SelectGuid v-model="searchData.guide_id"></SelectGuid>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tabs">
      <div :class="['item', searchData.type === null ? 'active' : '']" @click="handleClickTab(null)">
        <el-badge>
          <span class="tab-name">全部</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.type === 10 ? 'active' : '']" @click="handleClickTab(10)">
        <el-badge>
          <span class="tab-name">招标公告</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.type === 20 ? 'active' : '']" @click="handleClickTab(20)">
        <el-badge>
          <span class="tab-name">变更公告</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.type === 30 ? 'active' : '']" @click="handleClickTab(30)">
        <el-badge>
          <span class="tab-name">中标公告</span>
        </el-badge>
      </div>
    </div>
    <!-- 新增 -->
    <el-button
        style="margin-bottom: 10px"
        type="primary"
        icon="el-icon-plus"
        size="mini"
        plain
        @click="autoDialogVisible=true"
    >新增
    </el-button>
    <el-table :data="list">
      <el-table-column prop="parentName" label="所属类型" width="width" min-width="40px">
        <template slot-scope="{ row }">
        <el-tag size="small">{{ row.type_dsc }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="guide_names" label="导航类型" width="width" min-width="40px">
      </el-table-column>
      <el-table-column prop="city_name" label="城市" width="width" min-width="40px"></el-table-column>
      <el-table-column prop="title" label="标题" width="width" min-width="150px"></el-table-column>
      <el-table-column prop="publish_time" label="发布时间" width="width" min-width="40px"></el-table-column>
      <el-table-column label="操作" width="150px" fixed="right">
        <template slot-scope="{ row }">
          <el-link
            style="margin-right: 10px"
            type="primary"
            :underline="false"
            @click="$router.push({ name: 'BiaoXunEdit', params: { id: row.id } })"
            >编辑</el-link
          >
          <el-link type="primary" :underline="false" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize" @pagination="getList" />

    <el-dialog
        title="自动同步"
        :visible.sync="autoDialogVisible"
        width="40%"
        :before-close="handleAutoClose">
      <el-form ref="form" :model="autoFormData" label-width="80px">
        <el-form-item label="地址">
          <el-input v-model="autoFormData.page_detail_url"></el-input>
        </el-form-item>
        <el-form-item label="标题">
          {{autoFormData.title}}
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSync">同步</el-button>
          <el-button @click="autoDialogVisible=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getListAPI, delAPI, addAPI, editAPI, getDetailAPI, changeStatusAPI, spiderOnlinePageAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import SelectGuid from '@/views/biaoxun/biaoxun/select-guide.vue'

export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: { SelectGuid },
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      show_update: false,
      show_review: false,
      list: [],
      city_list:[],
      searchData: {
        page: 1,
        pagesize: 10,
        title: '',
        city_name: '',
        type: null,
        guide_id: null
      },
      total: 0,
      changeStatusData: {
        id: '',
        status: null
      },
      autoDialogVisible: false,
      autoFormData: {
        page_detail_url: '',
        title: '',
      },
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {  this.getList()},

  methods: {
    handleAutoClose() {
      this.autoDialogVisible = false
    },

    onSync() {
      spiderOnlinePageAPI(this.autoFormData).then((res) => {
        this.autoFormData.page_detail_url = ""
        this.autoFormData.title = res['name']
        this.$message.success('同步成功' + res['name'])
      })
    },

    handleClickTab (val) {
      if (this.searchData.type == val) return
      this.searchData.type = val
      this.getList()
    },
      // 审核弹窗
    getStatus(row) {
      this.changeStatusData = {
        id: row.id,
        status: row.status
      }
      this.show_review = true
    },
    changeStatus() {
      changeStatusAPI(this.changeStatusData).then(() => {
        this.$message.success('提交成功')
        this.getList()
        this.show_review = false
      })
    },
    async getList () {
      // 获取get 参数
      let params = { ...this.searchData }
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    // del
    async handleDel({ id }) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 新增
    handleAdd() {
      this.show_update = true
      this.$nextTick(() => {
        this.formData = {
          name: '',
          icon: '',
          describe: ''
        }
        this.$refs.form.resetFields()
      })
    },
    // edit
    async handleEdit({ id }) {
      this.show_update = true
      this.$nextTick(async () => {
        this.formData = await getDetailAPI(id)
        this.$refs.form.resetFields()
      })
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.getList()
              this.show_update = false
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.getList()
              this.show_update = false
            })
          }
        }
      })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
      }
    }
  }
  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;
    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }
    .active {
      color: #409eff;
      font-weight: 600;
      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }
  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }
  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 70px !important;
    margin: 0;
  }
}
</style>
<style lang="scss" scoped>
.icon-box /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 108px;
    height: 108px;
    line-height: 108px;
    text-align: center;
  }
  .avatar {
    width: 108px;
    height: 108px;
    display: block;
  }
}
</style>
